export const damageByUnit = [
  {
    units: "1",
    damage: "2",
  },
  {
    units: "2",
    damage: "4",
  },
  {
    units: "3",
    damage: "6",
  },
  {
    units: "4",
    damage: "8",
  },
  {
    units: "5",
    damage: "10",
  },
  {
    units: "6",
    damage: "11",
  },
  {
    units: "7",
    damage: "12",
  },
  {
    units: "8",
    damage: "13",
  },
  {
    units: "9",
    damage: "14",
  },
  {
    units: "10",
    damage: "15",
  },
];

export const damageByRound = [
  {
    round: "1",
    damage: "0",
  },
  {
    round: "2",
    damage: "0",
  },
  {
    round: "3",
    damage: "3",
  },
  {
    round: "4",
    damage: "4",
  },
  {
    round: "5",
    damage: "6",
  },
  {
    round: "6",
    damage: "9",
  },
  {
    round: "7+",
    damage: "15",
  },
];
